// sass-lint:disable no-mixed-declarations

:root {
	/**
	 * WARNING: Please keep colors.ts in sync with this!
	 */

	// Action colors
	--action-color: #007533;
	--action-hovered-color: #003824;
	--sub-menu-active: #eaf5f0;

	// Primary colors
	--primary-green: #007533;
	--primary-yellow: #ffc200;
	--primary-live-green: #00db33;
	--primary-dark-green: #003824;

	// Accent colors
	--accent-pink: #ff87b5;
	--accent-yellow: #ffc200;
	--accent-beige: #debf9e;
	--accent-live-green: #00db33;
	--accent-orange: #f2610f;

	// Background colors
	--bg-dark-green-full: #003824;
	--bg-green-full: #007533;
	--bg-beige-light: #f8f1e8;
	--bg-beige-full: #debf9e;
	--bg-yellow-light: #fff7db;
	--bg-yellow-full: #ffc200;
	--bg-yellow-medium-full: #ffd140;
	--bg-yellow-dark-full: #e5af02;
	--bg-purple-full: #400f54;
	--bg-beige-extra-light: #fcf9f6;
	--bg-dark-green-extra-light: #f5f7f7;
	--bg-white: white;
	--bg-blue-light: #d7edf4;
	--bg-light-grey: #f2f4f3;
	--bg-pink-light: #ff87b54d;
	--bg-pink: #ff87b5;
	--bg-green-light: #e6ecea;
	--bg-mint-green-full: #deffde;
	--bg-mint-green-light: #f5fff5;
	--bg-mint-green-medium: #efffef;
	--bg-red-light: #f9ecec;
	--bg-purple-light: #eae5ed;

	// Support colors
	--support-light: #fbbad3;
	--support-full: #cc336e;

	// Text colors
	--text-heading-color: #003824;
	--text-dark: #002517;
	--text-light: white;
	--text-mint-green: #deffde;
	--text-yellow: #ffc200;
	--text-primary-green: #007533;
	--text-pink: #ff87b5;
	--text-purple: #400f54;
	--text-pink-full: #cc336e;
	--text-blue-full: #095e8e;

	// Custom web colors
	--custom-yellow-light: #fedf7d;
	--custom-gray: #979797;
	--custom-text-placeholder: #656d6a;

	//opacity colors
	--primary-yellow-75: #ffc200bf;
	--primary-yellow-25: #ffc20040;
	--primary-yellow-10: #fff9e6;
	--primary-beige-25: #ecdac6;
	--primary-green-05: #f3f9f5;
	--primary-green-10: #e6f1eb;
	--primary-green-60: #deffde99;
	--primary-dark-green-10: #e6ebea;
	--bg-dark-green-full-90: #003824e6;
	--bg-purple-full-10: #ece7ee;
	--bg-purple-full-05: #f6f4f7;
	--border-color-70: #6e6e73b3;
	--bg-white-90: #ffffffe6;

	// Border colors
	--line-color: #dde1e0;
	--border-color-light: #cbcfce;
	--border-color: #8e9491;
	--border-hovered-color: #003824;
	--border-color-pink: #ff87b5;
	--border-color-yellow: #ffc200;
	--border-color-red: #eb978e;
	--border-color-blue: #85b9d6;
	--border-color-beige: #ecdac6;
	--border-color-green: #a1cbad;

	// Utility colors
	--selected-color: #f2f4f3;
	--highlight-color: #ffc200;
	--disabled-color: #bfc6c4;
	--danger-color: #930e00;
	--read-only: #656d6a;

	// Chart colors
	--chart-color-red: #eb978e;
	--chart-color-yellow: #ffe699;
	--chart-color-green: #a1cbad;

	//Colors used in prototype but not found here
	--card-five-yellow-light: #fff7db;
	--card-five-form-yellow: #ffe699;
	--red-400: #c12208;
	--sidenav-green: #2f614f;

	// Main colors based on CSS variables
	--body-color: var(--text-dark);
	--title-color: var(--text-heading-color);
	--breadcrumbs-color: var(--text-dark);

	// <BrandStripes>
	--brand-stripe-1-bg: var(--primary-green);
	--brand-stripe-2-bg: var(--primary-yellow);
	--brand-stripe-3-bg: var(--primary-live-green);
}

// sass-lint:enable no-mixed-declarations
